// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/mnt/my-new-disk/mindreader_cms/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/umi/plugin/openapi",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-openapi__openapi' */'/mnt/my-new-disk/mindreader_cms/src/.umi/plugin-openapi/openapi.tsx'), loading: LoadingComponent})
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/mnt/my-new-disk/mindreader_cms/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/~demos/:uuid",
        "layout": false,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent})],
        "component": ((props) => dynamic({
          loader: async () => {
            const React = await import('react');
            const { default: getDemoRenderArgs } = await import(/* webpackChunkName: 'dumi_demos' */ '/mnt/my-new-disk/mindreader_cms/node_modules/@umijs/preset-dumi/lib/plugins/features/demo/getDemoRenderArgs');
            const { default: Previewer } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi-theme-default/es/builtins/Previewer.js');
            const { usePrefersColor, context } = await import(/* webpackChunkName: 'dumi_demos' */ 'dumi/theme');

            return props => {
              
      const { demos } = React.useContext(context);
      const [renderArgs, setRenderArgs] = React.useState([]);

      // update render args when props changed
      React.useLayoutEffect(() => {
        setRenderArgs(getDemoRenderArgs(props, demos));
      }, [props.match.params.uuid, props.location.query.wrapper, props.location.query.capture]);

      // for listen prefers-color-schema media change in demo single route
      usePrefersColor();

      switch (renderArgs.length) {
        case 1:
          // render demo directly
          return renderArgs[0];

        case 2:
          // render demo with previewer
          return React.createElement(
            Previewer,
            renderArgs[0],
            renderArgs[1],
          );

        default:
          return `Demo ${props.match.params.uuid} not found :(`;
      }
    
            }
          },
          loading: () => null,
        }))()
      },
      {
        "path": "/_demos/:uuid",
        "redirect": "/~demos/:uuid"
      },
      {
        "__dumiRoot": true,
        "layout": false,
        "path": "/~docs",
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'../dumi/layout'), loading: LoadingComponent}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/mnt/my-new-disk/mindreader_cms/node_modules/dumi-theme-default/es/layout.js'), loading: LoadingComponent})],
        "routes": [
          {
            "path": "/~docs",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'README.md' */'/mnt/my-new-disk/mindreader_cms/README.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "locale": "en-US",
              "order": null,
              "filePath": "README.md",
              "updatedTime": 1638435714000,
              "slugs": [
                {
                  "depth": 1,
                  "value": "Ant Design Pro",
                  "heading": "ant-design-pro"
                },
                {
                  "depth": 2,
                  "value": "Environment Prepare",
                  "heading": "environment-prepare"
                },
                {
                  "depth": 2,
                  "value": "Provided Scripts",
                  "heading": "provided-scripts"
                },
                {
                  "depth": 3,
                  "value": "Start project",
                  "heading": "start-project"
                },
                {
                  "depth": 3,
                  "value": "Build project",
                  "heading": "build-project"
                },
                {
                  "depth": 3,
                  "value": "Check code style",
                  "heading": "check-code-style"
                },
                {
                  "depth": 3,
                  "value": "Test code",
                  "heading": "test-code"
                },
                {
                  "depth": 2,
                  "value": "Deployment",
                  "heading": "deployment"
                },
                {
                  "depth": 3,
                  "value": "PM2",
                  "heading": "pm2"
                },
                {
                  "depth": 2,
                  "value": "More",
                  "heading": "more"
                }
              ],
              "title": "Ant Design Pro"
            },
            "title": "Ant Design Pro"
          },
          {
            "path": "/~docs/components",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'components__index.md' */'/mnt/my-new-disk/mindreader_cms/src/components/index.md'), loading: LoadingComponent}),
            "exact": true,
            "meta": {
              "filePath": "src/components/index.md",
              "updatedTime": 1634729902000,
              "title": "业务组件",
              "sidemenu": false,
              "slugs": [
                {
                  "depth": 1,
                  "value": "业务组件",
                  "heading": "业务组件"
                },
                {
                  "depth": 2,
                  "value": "Footer 页脚组件",
                  "heading": "footer-页脚组件"
                },
                {
                  "depth": 2,
                  "value": "HeaderDropdown 头部下拉列表",
                  "heading": "headerdropdown-头部下拉列表"
                },
                {
                  "depth": 2,
                  "value": "HeaderSearch 头部搜索框",
                  "heading": "headersearch-头部搜索框"
                },
                {
                  "depth": 3,
                  "value": "API",
                  "heading": "api"
                },
                {
                  "depth": 2,
                  "value": "NoticeIcon 通知工具",
                  "heading": "noticeicon-通知工具"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon API",
                  "heading": "noticeicon-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIcon.Tab API",
                  "heading": "noticeicontab-api"
                },
                {
                  "depth": 3,
                  "value": "NoticeIconData",
                  "heading": "noticeicondata"
                },
                {
                  "depth": 2,
                  "value": "RightContent",
                  "heading": "rightcontent"
                }
              ],
              "hasPreviewer": true,
              "group": {
                "path": "/~docs/components",
                "title": "Components"
              }
            },
            "title": "业务组件 - ant-design-pro"
          }
        ],
        "title": "ant-design-pro",
        "component": (props) => props.children
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/mnt/my-new-disk/mindreader_cms/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/mnt/my-new-disk/mindreader_cms/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/mnt/my-new-disk/mindreader_cms/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/clients",
        "name": "Users",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Clients' */'/mnt/my-new-disk/mindreader_cms/src/pages/Clients'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/client/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Clients__Components__ClientDetail' */'/mnt/my-new-disk/mindreader_cms/src/pages/Clients/Components/ClientDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/plans",
        "name": "Plans",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Plans' */'/mnt/my-new-disk/mindreader_cms/src/pages/Plans'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/plan/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Plans__Components__PlanDetail' */'/mnt/my-new-disk/mindreader_cms/src/pages/Plans/Components/PlanDetail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/add-plan",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Plans__Components__AddPlan' */'/mnt/my-new-disk/mindreader_cms/src/pages/Plans/Components/AddPlan'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/insights",
        "name": "Insights",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Insights' */'/mnt/my-new-disk/mindreader_cms/src/pages/Insights'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/insights-blog",
        "name": "Insights Blog",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog' */'/mnt/my-new-disk/mindreader_cms/src/pages/Blog'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/insights-blog-article/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__Detail' */'/mnt/my-new-disk/mindreader_cms/src/pages/Blog/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/insights-blog-article",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Blog__Detail' */'/mnt/my-new-disk/mindreader_cms/src/pages/Blog/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/descriptors",
        "name": "Quiz Result",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Descriptors' */'/mnt/my-new-disk/mindreader_cms/src/pages/Descriptors'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/descriptors/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Descriptors__Detail' */'/mnt/my-new-disk/mindreader_cms/src/pages/Descriptors/Detail'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/tips/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Insights__Tips' */'/mnt/my-new-disk/mindreader_cms/src/pages/Insights/Tips'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/types/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Insights__Tips__Types' */'/mnt/my-new-disk/mindreader_cms/src/pages/Insights/Tips/Types'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/type-content/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Insights__Tips__Types__TypeContent' */'/mnt/my-new-disk/mindreader_cms/src/pages/Insights/Tips/Types/TypeContent'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/subscribers",
        "name": "Subscribers",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Subscribers' */'/mnt/my-new-disk/mindreader_cms/src/pages/Subscribers'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/interest-surveys",
        "name": "Interest Surveys",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__InterestSurveys' */'/mnt/my-new-disk/mindreader_cms/src/pages/InterestSurveys'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/contact",
        "name": "Contacts",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contacts' */'/mnt/my-new-disk/mindreader_cms/src/pages/Contacts'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/mnt/my-new-disk/mindreader_cms/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
